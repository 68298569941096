<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input size="mini" class="iw-md" v-model="searchinfo.title" placeholder="微信号" label="请输入微信号"
                  clearable></el-input>
        <el-input size="mini" class="iw-md" v-model="searchinfo.realname" placeholder="真实姓名" label="请输入真实姓名"
                  clearable></el-input>
        <el-input size="mini" class="iw-md" v-model="searchinfo.mobile" placeholder="手机号码" label="请输入手机号码"
                  clearable></el-input>
        <el-input size="mini" class="iw-md" v-model="searchinfo.leader" placeholder="团队长昵称" label="请输入团队长昵称"
                  clearable></el-input>
        <el-input size="mini" class="iw-md" v-model="searchinfo.partner" placeholder="合伙人昵称" label="请输入合伙人昵称"
                  clearable></el-input>
        <el-select size="mini" class="iw-md" v-model="searchinfo.isleader" placeholder="是否团队长">
          <el-option value="0" label="否"></el-option>
          <el-option value="1" label="是"></el-option>
        </el-select>
        <el-button size="mini" type="primary" @click="dosearch()" icon="el-icon-search" :disabled="issearch" :loading="issearch">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" size="mini" @click="add('form')" icon="el-icon-plus">新增会员</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                border
                size="mini"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column :label="$t('global.action')" width="150" fixed="left">
          <template slot-scope="scope">
            <el-dropdown size="mini" split-button type="primary" @command="handleCommand">
              更多菜单
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-reading" :command="composeValue(scope.row,'edit')">编辑会员信息</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" :command="composeValue(scope.row,'leader')" v-if="scope.row.is_leader == 0 && scope.row.leader_id == 0">设置团队长</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" :command="composeValue(scope.row,'delete')" >删除会员</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
                label="团队长"
                prop="leader"
                min-width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.leader != ''">
              <div>团队名：{{scope.row.leader.team_name}}</div>
              <div>团队长姓名：{{scope.row.leader.leader_name}}</div>
              <div>微信昵称：{{scope.row.leader.nickname}}</div>
            </div>
            <div v-else>---</div>
          </template>
        </el-table-column>
        <el-table-column
                label="邀请人"
                prop="invited"
                min-width="120">
          <template slot-scope="scope">
            {{ scope.row.invited == '' ? '---' : scope.row.invited }}
          </template>
        </el-table-column>
        <el-table-column
            label="微信号"
            prop="wacc"
            min-width="120">
        </el-table-column>
        <el-table-column
            label="真实姓名"
            prop="realname"
            width="100">
        </el-table-column>
        <el-table-column
                label="是否团队长"
                prop="is_leader"
                min-width="100">
          <template slot-scope="scope">
            <el-tag size="small" type="warning" v-if="scope.row.is_leader == 0">否</el-tag>
            <el-tag size="small" type="success" v-else>是</el-tag>
          </template>
        </el-table-column>
        <el-table-column
                label="是否合伙人"
                prop="is_partner"
                min-width="100">
          <template slot-scope="scope">
            <el-tag size="small" type="warning" v-if="scope.row.is_leader == 0 && scope.row.leader_id == 0">否</el-tag>
            <el-tag size="small" type="success" v-else>是</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="电子邮箱"
            prop="email"
            min-width="160">
        </el-table-column>
        <el-table-column
            label="联系电话"
            prop="mobile"
            width="100">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="100" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row,'status')">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.created_at')"
            width="170" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="noticeformdata.mid?'编辑会员':'新增会员'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w60">
      <el-form
          size="mini" :model="noticeformdata" :rules="rules" ref="form" :show-message="false">
        <el-form-item label="微信昵称" label-width="120px" prop="nickname" required>
          <el-input v-model="noticeformdata.nickname" :disabled="noticeformdata.mid?true:false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="微信号" label-width="120px" prop="wacc" required>
          <el-input v-model="noticeformdata.wacc" :disabled="noticeformdata.mid?true:false" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" label-width="120px" prop="realname" required>
          <el-input v-model="noticeformdata.realname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" label-width="120px" prop="email" required>
          <el-input v-model="noticeformdata.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系手机" label-width="120px" prop="mobile" required>
          <el-input v-model="noticeformdata.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户备注" label-width="120px" prop="remarks">
          <el-input type="textarea" v-model="noticeformdata.remarks" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('news.status')" label-width="120px" required>
          <el-switch v-model="noticeformdata.status" :active-value="1" :inactive-value="0"
                     :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            size="mini" @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button
            size="mini" type="primary" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :title="leaderformdata.mid?'设置团队长':'设置团队长'" :visible.sync="isshowdialogs"
               :close-on-click-modal="false" custom-class="w40">
      <el-form size="mini" :model="leaderformdata" :rules="ruless" ref="leaderform" :show-message="false">
        <el-form-item label="团队名称" label-width="120px" prop="team_name" required>
          <el-input v-model="leaderformdata.team_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="团队长姓名" label-width="120px" prop="leader_name" required>
          <el-input v-model="leaderformdata.leader_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isshowdialogs = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button size="mini" type="primary" @click="doleadersubmit('leaderform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script type="text/javascript">
export default {
  name: 'member',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
        realname: '',
        mobile: '',
        isleader:'',
        leader:'',
        partner:'',
      },
      list: [],
      totalRows: 0,
      defaultuserinfo: {
        mid: 0,
        nickname:'',
        wacc: '',
        avatar:'',
        realname: '',
        email: '',
        mobile: '',
        status: 1,
        remarks:''
      },
      noticeformdata: {},
      leaderformdata:{},
      rules: {},
      ruless:{},
      isshowdialog: false,
      isshowdialogs: false,
      issearch: false,
      isbusy: false,
    }
  },
  methods: {
    handleCommand(command){
      if(command.command == 'edit'){
        this.isshowdialog = true;
        this.noticeformdata = null;
        this.noticeformdata = this.cloneData(command.items);
      } else if(command.command == 'delete') {
        this.$confirm(this.$t('global.areyousure')).then(async () => {
          this.isbusy = true;
          let res = await this.httpput('/system/member/delete', {mid: command.items.mid});
          if (res.code === 0) {
            this.$message({type: 'success', message: res.msg});
            this.getlist();
          } else {
            this.$message({type: 'error', message: res.msg});
          }
          this.isbusy = false;
        }).catch(() => {
          this.isbusy = false;
          this.$message({type: 'info', message: 'Canceled'});
        });
      } else if(command.command == 'leader') {
        this.leaderformdata = {
          mid:command.items.mid,
          leader_name:command.items.leader.leader_name,
          team_name:command.items.leader.team_name
        };
        this.isshowdialogs = true;
      }

    },
    composeValue(items,command){
      return {
        'items':items,
        'command':command
      };
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async edit(item) {
      this.noticeformdata = {};
      this.isshowdialog = true;
      this.$nextTick(()=>{
        this.noticeformdata = item;
      });
    },
    add(formName) {
      this.noticeformdata = {};
      this.noticeformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.issearch = true;
      let res = await this.httpget('/system/member/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.totalRows = res.data.total;
        this.issearch = false;
      } else {
        this.issearch = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/member/save', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    doleadersubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureleadersubmit();
        } else {
          return false;
        }
      });
    },
    async sureleadersubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/member/setleader', this.leaderformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.leaderformdata = {};
        this.isshowdialogs = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item,field) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/member/setenable', {mid: item.mid,field:field});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.mid === item.mid
          })
          if (idx !== -1) {
            if( field == 'status' ){
              this.list[idx].status = Math.abs(this.list[idx].status - 1);
            } else if( field == 'is_index' ) {
              this.list[idx].is_index = Math.abs(this.list[idx].is_index - 1);
            } else {
              this.list[idx].is_top = Math.abs(this.list[idx].is_top - 1);
            }
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
    baseurl: {
      get: function () {
        return this.$store.getters.getSerdomain;
      }
    },
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}

.edui-popup-content.edui-default {
  height: auto !important;
}

.edui-combox-body.edui-default {
  height: 25px;

}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px !important;
}

.edui-default .edui-toolbar .edui-splitborder {
  height: 25px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
  height: 25px !important;
  width: 13px !important;
}

#edui_fixedlayer {
  z-index: 100000 !important;
}
</style>
